<template>
  <TheHead v-if="profileInfo" :title="profileInfo.full_name"></TheHead>
  <ViewCV :urlPreview="urlPreview" />
  <main v-if="profileInfo">
    <div class="container d-flex justify-content-center mt-5">
      <div class="text-center" style="max-width: 500px">
        <img
          style="max-height: 300px; max-width: 300px"
          :src="profileInfo.photoUrl"
          class="rounded-circle img-fluid"
          :alt="profileInfo.full_name"
          @error="
            this.profileInfo.photoUrl = require('@/assets/img/default-user.jpg')
          "
        />
        <h2 class="text-center mt-2">{{ profileInfo.full_name }}</h2>
        <h6 class="text-center">{{ profileInfo.profession }}</h6>
        <button @click="show" type="button" class="btn btn-primary me-2 mt-2">
          Resume/CV
        </button>

        <p class="text-center text-small p-4">{{ profileInfo.aboutMe }}</p>
      </div>
    </div>
  </main>
</template>

<script>
import api from "@/services/api";
import actions from "@/plugins/actions";
import { Modal } from "bootstrap";
import ViewCV from "@/components/modal/ViewCV";
import TheHead from "@/components/TheHead";
export default {
  name: "Profile",
  components: {
    ViewCV,
    TheHead,
  },
  data() {
    return {
      urlPreview: "",
      profileInfo: null,
    };
  },
  mounted() {
    actions.dispatch("loading", true);

    this.mdPreview = new Modal(document.getElementById("mdPreview"));

    api({
      url: `getPublicCurriculum`,
      method: "POST",
      data: {
        token: this.$route.params.token,
      },
      responseType: "arraybuffer",
    })
      .then((response) => {
        try {
          var decodedString = String.fromCharCode.apply(
            null,
            new Uint8Array(response.data)
          );
          let obj = JSON.parse(decodedString);
          if (obj["error"]) {
            throw new Error(obj["messages"]);
          }
        } catch (e) {
          //this.saveConfig();
          // this.mdModel.hide();
        }

        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        this.urlPreview = window.URL.createObjectURL(blob);
        this.mdPreview.show();
      })
      .catch(() => {
        this.$router.push({ name: "not-found" });
        this.loading = false;
      })
      .finally(() => {
        actions.dispatch("loading", false);
      });

    api({
      method: "post",
      url: "/getPublicUser",
      data: {
        token: this.$route.params.token,
      },
    })
      .then((response) => {
        if (response.data.status == "200") {
          this.profileInfo = response.data.data.curriculum;
        } else throw new Error(response.data.messages);
      })
      .catch(() => {
        this.$router.push({ name: "not-found" });
      });
  },
  computed: {},

  methods: {
    defaultImage() {
      this.profileInfo.photoUrl = require("@/assets/img/default-user.jpg");
    },
    show() {
      this.mdPreview.show();
    },
  },
};
</script>
<style></style>
<style scoped>
footer,
header {
  display: none;
}
</style>
